<template>
     <v-app-bar
        color="deep-purple accent-4"
        dense
        dark
      >
        
  
        <v-toolbar-title>Page title</v-toolbar-title>
  
        <v-spacer></v-spacer>
      </v-app-bar>
</template>
<script>
export default {
  name: 'pageHeader',
}
</script>